var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "mt-6", attrs: { cols: "7" } },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": true,
            transition: "scale-transition",
            "offset-y": "",
            "nudge-left": "25",
            "nudge-top": "25",
            "max-width": "290px",
            "min-width": "290px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          label: _vm.attr.name,
                          "prepend-inner-icon": "mdi-calendar",
                          disabled: _vm.disabled,
                          "error-messages":
                            !_vm.readonly &&
                            _vm.attr.is_required &&
                            !_vm.dateFormatted &&
                            _vm.attrvalues.length === 0
                              ? _vm.attr.name + " is required."
                              : [],
                          outlined: "",
                          readonly: "",
                          dense: ""
                        },
                        on: { click: _vm.checkTimeSensitive },
                        model: {
                          value: _vm.dateFormatted,
                          callback: function($$v) {
                            _vm.dateFormatted = $$v
                          },
                          expression: "dateFormatted"
                        }
                      },
                      !_vm.readonly ? on : null
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _c("v-date-picker", {
            attrs: { "no-title": "" },
            on: { change: _vm.save },
            model: {
              value: _vm.newval,
              callback: function($$v) {
                _vm.newval = $$v
              },
              expression: "newval"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }