<template>
  <v-row align="center" :dense="attr.data_type.constant !== 'DATE'">
    <AttrTooltips
      :attr="attr"
      @activity="attrActivity = true"
      @info="attrInfo = true">
    </AttrTooltips>

    <component
      :is="attrComponent"
      :attr="attr"
      :attrvalue="attrvalue"
      :attrvalues="attrvalues"
      @save-attr="save"
      :disabled="isDisabled"
      @multi-value="hasWriteAccess ? multiValueModal = true : null"
      @time-sensitive="hasWriteAccess ? timeSensitiveDates = true : null"
      @refresh-values="$emit('refresh-values')"
      :readonly="!hasWriteAccess || attr.is_time_sensitive">
    </component>

    <AttrActivity
      v-if="attrActivity"
      :value.sync="attrActivity"
      :attr="attr"
      :attrvalue="attrvalue"
      :attrvalues="attrvalues">
    </AttrActivity>
    <AttrInfo
      v-if="attrInfo"
      :value.sync="attrInfo"
      :attr="attr"
      :attrvalue="attrvalue">
    </AttrInfo>
    <TimeSensitive
      v-if="timeSensitiveDates"
      @refresh-values="$emit('refresh-values')"
      :value.sync="timeSensitiveDates"
      :attr="attr"
      :attrvalue="attrvalue"
      :attrvalues="attrvalues"
      :attrComponent="attrComponent"
      :defaultStartDate="defaultStartDate">
    </TimeSensitive>
    <MultiValue
      v-if="multiValueModal"
      @refresh-values="$emit('refresh-values')"
      :value.sync="multiValueModal"
      :attr="attr"
      :attrvalue="attrvalue"
      :attrvalues="attrvalues">
    </MultiValue>
  </v-row>
</template>
<script>
// Components
const AttrActivity = () => import('@/components/modals/attr_activity')
const AttrInfo = () => import('@/components/modals/attr_info')
const MultiValue = () => import('@/components/modals/multi_value')
const TimeSensitive = () => import('@/components/modals/time_sensitive')
import AttrTooltips from '@/components/parties/attr_tooltips.vue'
import CheckBox from './form/checkbox'
import InputBox from './form/input_box'
import KeyValue from './form/key_value'
import DateInput from './form/date_input'

// Mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'

export default {
  data () {
    return {
      attrActivity: false,
      attrInfo: false,
      multiValueModal: false,
      timeSensitiveDates: false,
      disabledAttributes: ['OWNER_GROUP_CODE', 'USER_BLOCKED'], 
    }
  },
  name: 'AttributeInput',
  components: {
    AttrActivity,
    AttrInfo,
    MultiValue,
    TimeSensitive,
    AttrTooltips,
    CheckBox,
    InputBox,
    KeyValue,
    DateInput
  },
  props: ['attr', 'attrvalue', 'attrvalues', 'defaultStartDate', 'hasWriteAccess'],
  mixins: [displayAlert, userAccess],
  created () {
    if (!this.isIproAdmin && this.$auth.tenant === 'jbg') {
      this.disabledAttributes.push('S4_STORE_NUMBER')
    }
    if (this.$auth.tenant === 'jbg') {
      this.disabledAttributes.push('USER_AUTH0_ROLES', 'USER_LAST_LOGIN')
    }
  },
  computed: {
    attrComponent () {
      switch (this.attr.data_type.constant) {
        case 'BOOLEAN':
          return CheckBox
        case 'KEY_VALUE':
          return KeyValue
        case 'DATE':
          return DateInput
        case 'INTEGER':
          return InputBox
        case 'NUMERIC':
          return InputBox
        case 'STRING':
          return InputBox
        default:
          return InputBox
      }
    },
    timeSensitive () {
      return this.attr.is_time_sensitive
    },
    isKeyValue () {
      return this.attr.data_type.constant === 'KEY_VALUE'
    },
    isDisabled () {
      return this.disabledAttributes.includes(this.attr.id)
    },
  },
  methods: {
    async save (val) {
      if (this.timeSensitive) {
        return
      }
      const attrValueObj = this.buildSavePayload(val)
      if (this.attrvalue !== null) {
        this.$emit('update-attr', this.attrvalue, attrValueObj)
      } else {
        this.$emit('save-attr', this.attr, attrValueObj)
      }
    },
    buildSavePayload (val) {
      const payload = {
        attr_id: this.attr.id,
        party_id: this.$route.query.id,
        start_dt: this.defaultStartDate || this.moment().format('YYYY-MM-DD'),
        end_dt: null
      }
      if (this.isKeyValue) {
        payload['attr_key_value_id'] = (val && typeof val === 'object')
          ? val.id
          : val
      } else {
        payload['value'] = val
      }
      if (this.attrvalue !== null) {
        payload['updated_by'] = this.$store.getters.email
        payload.start_dt = this.attrvalue.start_dt
        return payload
      }
      payload['created_by'] = this.$store.getters.email
      return payload
    }
  }

}
</script>
